import FiatBalance from '../Grpahs/FiatBalance'
import * as S from './styles'
import { FontWeight } from 'app/styles/typography'
import { Wallet } from 'crypto/interface'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { Fade } from 'ui/Common/components/Animation'
import { AnimatedSpinnerIcon } from 'ui/Common/components/Animation/AnimatedSpinnerIcon'
import { Button, ButtonVariant } from 'ui/Common/components/Button'
import WalletLogo from 'ui/Common/components/WalletLogo'
import { formatBalanceAmount } from 'ui/Common/util/format/amountFormat'
import { calculateWalletFiatBalance } from 'ui/Wallet/utils/balance'

interface WalletListItemProps {
  wallet: Wallet
  isActive: boolean
  onClick: () => void
}

export function WalletListItem({
  wallet,
  isActive,
  onClick,
}: WalletListItemProps) {
  const router = useRouter()
  const hasSpinner = isActive && wallet.isPending

  const fiatBalanceHistory = calculateWalletFiatBalance(wallet)

  const walletAssetCount = useMemo(() => {
    return (
      wallet.assets
        // filter only assets with balance >0
        .filter((asset) => asset.unitBalance.gt(0)).length
    )
  }, [wallet])

  return (
    <S.Wrapper isActive={isActive} onClick={onClick}>
      <S.TitleWrapper hasSpinner={hasSpinner}>
        <S.NameSection isActive={isActive}>
          <WalletLogo walletId={wallet.id} width={24} height={24} />
          {wallet.name}
        </S.NameSection>

        <S.BalanceSection>
          <span style={{ fontWeight: FontWeight.Regular }}>$</span>
          {formatBalanceAmount(wallet.fiatBalance)}
        </S.BalanceSection>

        <AnimatedSpinnerIcon />
      </S.TitleWrapper>

      {isActive && (
        <>
          <S.AssetsWrapper>
            <Fade direction="down">
              {walletAssetCount} asset{walletAssetCount > 1 && 's'}
            </Fade>
          </S.AssetsWrapper>

          {
            //TODO: not used currently
            false && (
              <div className="graph">
                {<FiatBalance points={fiatBalanceHistory} />}
              </div>
            )
          }

          <div className="controls">
            <Fade direction="up">
              <S.ButtonsWrapper>
                {typeof wallet.id === 'number' && (
                  <Button
                    variant={ButtonVariant.Secondary}
                    color="red.red6"
                    onClick={() => console.log('disconnect')}
                  >
                    Disconnect
                  </Button>
                )}
                {wallet.id !== 'eternl' && (
                  <Button
                    variant={ButtonVariant.Secondary}
                    onClick={() =>
                      router.push(`/wallet/stats?wallet=${wallet.id}`)
                    }
                  >
                    Stats
                  </Button>
                )}
              </S.ButtonsWrapper>
            </Fade>
          </div>
        </>
      )}
    </S.Wrapper>
  )
}
