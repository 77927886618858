import React from 'react'

export const EthereumIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill={props.fill || '#627EEA'} fillRule="nonzero">
      <path fillOpacity=".602" d="M7.915.667v5.42l4.582 2.047z" />
      <path d="M7.915.667 3.333 8.134l4.582-2.047z" />
      <path fillOpacity=".602" d="M7.915 11.647v3.683L12.5 8.988z" />
      <path d="M7.915 15.33v-3.683l-4.582-2.66z" />
      <path fillOpacity=".2" d="m7.915 10.795 4.582-2.66-4.582-2.047z" />
      <path fillOpacity=".602" d="m3.333 8.134 4.582 2.66V6.089z" />
    </g>
  </svg>
)
