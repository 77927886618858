import styled, { keyframes } from 'styled-components'
import { LARGE_BREAKPOINT } from 'ui/Common/components/Grid'
import { SpinnerIcon } from 'ui/Icons/components/SpinnerIcon'

interface IsActive {
  isActive?: boolean
}

export const Wrapper = styled.div<IsActive>`
  border-radius: 8px;
  display: flex;
  flex-flow: column nowrap;
  ${(p) => p.isActive && 'justify-content: space-between'};
  cursor: pointer;
  color: ${(p) => (p.isActive ? 'white' : p.theme.colors.grey.grey6)};
  transition: height 0.25s ease-in-out, background-color 0.1s, color 0.1s;

  height: ${(p) => (p.isActive ? 244 : 86)}px;
  padding: ${(p) => (p.isActive ? '0 48px 36px' : '0 48px')};
  background-color: ${(p) =>
    p.isActive ? 'black' : p.theme.colors.grey.grey5};

  margin-top: 4px;

  @media (max-width: ${LARGE_BREAKPOINT}px) {
    padding: ${(p) => (p.isActive ? '0 36px 32px' : '0 36px')};
    height: ${(p) => (p.isActive ? 188 : 76)}px;
  }

  .graph {
    display: inline;
    text-align: right;
  }

  .controls {
    display: none;
  }

  :hover {
    background-color: ${(p) =>
      p.isActive ? 'black' : p.theme.colors.grey.grey6};
    color: white;

    .graph {
      display: none;
    }

    .controls {
      display: initial;
    }
  }

  :active {
    background-color: black;
    color: white;
  }
`

export const TitleWrapper = styled.div<IsActive & { hasSpinner?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: ${(p) => (p.isActive ? 154 : 86)}px;

  > svg {
    position: absolute;
    right: -28px;
    top: calc(50% - 10px);
    transition: opacity 0.25s ease-in-out;

    opacity: ${(p) => (p.hasSpinner ? 1 : 0)};
    pointer-events: ${(p) => (p.hasSpinner ? 'initial' : 'none')};
  }
`

export const NameSection = styled.div<IsActive>`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;

  > svg {
    transition: transform 0.25s ease-in-out, margin 0.25s ease-in-out;

    margin: ${(p) => (p.isActive ? '8px 32px 8px 8px' : '0 24px 0 0')};
    transform: ${(p) => (p.isActive ? 'scale(1.33)' : 'initial')};
  }
`

export const BalanceSection = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin-left: 8px;
  }
`

export const AssetsWrapper = styled.div`
  margin: -24px 0 auto;
  text-align: right;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
`
