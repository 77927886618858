/**
 * Graph variant colors.
 */
const GraphVariants = {
  positive: {
    primary: '#68d04f',
    fadeStart: 'rgba(104, 208, 79, .3)',
    fadeEnd: 'rgba(104, 208, 79, .1)',
  },
  negative: {
    primary: '#fd003d',
    fadeStart: 'rgba(249, 62, 107, .3)',
    fadeEnd: 'rgba(249, 62, 107, .1)',
  },
  inverted: {
    primary: '#fff',
    fadeStart: 'rgba(255, 255, 255, .3)',
    fadeEnd: 'rgba(255, 255, 255, .1)',
  },
}

/**
 * Normalize an array of (positive!) values to the 0-1 interval.
 */
export function normalize(arr: number[]) {
  const max = Math.max(...arr)
  const min = Math.min(...arr)

  return arr.map((e) => (e - min) / (max - min))
}

/**
 * Invert normalized array.
 */
export function invert(arr: number[]) {
  return arr.map((e) => 1 - e)
}

/**
 * Get a color variant for the `FiatBalance` graph.
 */
export function getGraphColors(points: number[], inverted?: boolean) {
  const isNegative = points[0] > points[points.length - 1]
  return inverted
    ? GraphVariants.inverted
    : isNegative
    ? GraphVariants.negative
    : GraphVariants.positive
}
