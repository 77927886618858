import { Wallet } from 'crypto/interface'

/**
 * Calculate fiat balance change for the wallet based on
 * the fiat balance change of the assets inside the wallet.
 */
export function calculateWalletFiatBalance(wallet: Wallet) {
  // const assetShare = {}

  // wallet.assets.forEach(
  //   (a) => (assetShare[a.id] = a.fiatBalance / wallet.fiatBalance)
  // )

  // ! TODO: finish implementation

  return wallet.assets[0]?.fiatBalanceHistory || []
}
