import { ChainType } from 'crypto/interface'
import styled, { css } from 'styled-components'

export type StyleVariant = 'grayscale'

export const ToggledChainName = styled.div<{ chain?: ChainType }>`
  color: ${(p) => p.color};
  text-transform: uppercase;
  letter-spacing: 1px;

  ${(p) => {
    if (p.chain === 'cardano') {
      return css`
        color: #fd003d;
      `
    } else if (p.chain === 'ethereum') {
      return css`
        color: #627eea;
      `
    } else if (p.chain === 'polygon') {
      return css`
        color: #4a44c6;
      `
    }
  }}
`

export const ChainWrapper = styled.div<{
  toggleName?: boolean
  variant?: StyleVariant
}>`
  display: flex;
  align-content: center;
  background-color: transparent;
  gap: ${(p) => p.theme.spacing.xss}px;
  align-items: center;

  ${(p) => {
    if (p.variant === 'grayscale') {
      return css`
        filter: grayscale() brightness(2.5);
      `
    }
  }}

  ${(p) =>
    p.toggleName &&
    css`
      ${ToggledChainName} {
        opacity: 0;
        transition: 0.2s opacity ease-in;
      }

      :hover {
        ${ToggledChainName} {
          width: auto;
          opacity: 1;
        }
      }
    `}
`
