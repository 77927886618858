import { getFontFromKey } from 'app/styles/typography'
import styled from 'styled-components'

export const DEFAULT_INDICATOR_SIZE = 40

export const Container = styled.div<{ size?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(p) => (p.size ?? DEFAULT_INDICATOR_SIZE) * 3}px;

  color: ${(p) => p.theme.colors.grey.grey5};
  ${getFontFromKey('paragraph.4')};
`
