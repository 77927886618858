import React, { PropsWithChildren } from 'react'
import * as S from 'ui/Common/components/EmptyListMessage/styles'

// -- Prop types
// ----------

export interface EmptyListMessageProps {
  message: React.ReactNode
  size?: number
}

// -- Component
// ----------

/** Show standard empty list box with a message inside it */
const EmptyListMessage: React.FC<PropsWithChildren<EmptyListMessageProps>> = (
  props
) => {
  return <S.Container size={props.size}>{props.message}</S.Container>
}

export { EmptyListMessage }
