import { ChainType } from 'crypto/interface'
import React from 'react'
import { CardanoIcon } from 'ui/Icons/components/CardanoIcon'
import { EthereumIcon } from 'ui/Icons/components/EthereumIcon'
import { PolygonIcon } from 'ui/Icons/components/PolygonIcon'

interface ChainLogoProps {
  chain: ChainType
  width?: number
  height?: number
}

export const ChainLogo: React.VFC<ChainLogoProps> = ({
  chain,
  width,
  height,
}) => {
  switch (chain) {
    case 'cardano':
      return <CardanoIcon width={width} height={height} />
    case 'ethereum':
      return <EthereumIcon width={width} height={height} />
    case 'polygon':
      return <PolygonIcon width={width} height={height} />
  }
}
