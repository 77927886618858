import { getGraphColors, invert, normalize } from './utils'
import { motion } from 'framer-motion'
import React, { useRef } from 'react'

const GRAPH_MAX_VALUE = 20
const GRAPH_WIDTH = 136

const STEP = GRAPH_WIDTH / 24 // graph shows intraday change

/**
 * Convert an array of y-coordinates to polyline points.
 * y-coordinates represent values for the last 24 hours.
 */
function getPolylinePoints(arr: number[]) {
  let ret = ''

  arr.forEach((a, i) => (ret += `${i * STEP},${a} `))
  return ret
}

/**
 * Pad the array `arr` form the start with zeros until total array length equals `size`.
 */
function padStart(arr: number[], size = 24) {
  if (arr.length === size) return arr

  const len = arr.length
  const zeros = new Array(size - len).fill(GRAPH_MAX_VALUE)

  return [...zeros, ...arr]
}

/**
 * The Fiat Balance graph interface.
 */
type FiatBalanceProps = {
  /**
   * Set stroke/fill for the graph to white,
   * so it could be displayed on green/red surface.
   */
  inverted?: boolean
  /**
   * Raw (fiat) value of the asset for the last N hours.
   */
  points: number[]
}

/**
 * Root container for the $FIAT balance mini graph
 * rendered in the asset list.
 *
 * The area graph displays the change of the asset for the past 24 hours.
 */
function FiatBalance(props: FiatBalanceProps) {
  const { inverted, points } = props

  const { current: uuid } = useRef(Math.random())
  const colors = getGraphColors(points, inverted)

  const scaledPoints =
    /* invert since highest y-value is the lowest on the screen */
    invert(
      /* pad with zeros from the start until total length is 24 */
      padStart(
        /* normalize values to get 0-1 range */
        normalize(points)
      )
    ).map((p) => Math.floor(p * GRAPH_MAX_VALUE))

  // compute polyline points string from the scaled (and padded) points
  let polylinePoints = getPolylinePoints(scaledPoints)

  // To fill polyline properly we need to "close" it
  // from the sides with the `GRAPH_MAX_VALUE`.

  // We prepend the starting value and append the last value
  // so the graph doesn't "sink" on the edges towards the closing value.
  polylinePoints = `
    ${-2 * STEP},${GRAPH_MAX_VALUE + 1}
    ${-STEP},${scaledPoints[0]}

    ${polylinePoints} 

    ${GRAPH_WIDTH + STEP},${scaledPoints[scaledPoints.length - 1]}
    ${GRAPH_WIDTH + 2 * STEP},${GRAPH_MAX_VALUE + 1}
  `

  const polylineTransitionStates = {
    hidden: { pathLength: 0, fill: 'transparent' },
    visible: (i: number) => {
      const delay = 1 + i * 0.5
      return {
        pathLength: 1,
        opacity: 1,
        fill: `url(#area-${uuid})`,
        transition: {
          pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
          fill: { delay: 1, duration: 0.01 },
        },
      }
    },
  }

  return (
    <motion.svg
      initial="hidden"
      animate="visible"
      width={GRAPH_WIDTH}
      height={GRAPH_MAX_VALUE}
      viewBox={`0 0 ${GRAPH_WIDTH} ${GRAPH_MAX_VALUE}`}
    >
      <defs>
        <linearGradient id={`area-${uuid}`} gradientTransform="rotate(90)">
          <stop offset="0" stopColor={colors.fadeStart} />
          <stop offset="97%" stopColor={colors.fadeEnd} />
        </linearGradient>
      </defs>

      <motion.polyline
        fill={`url(#area-${uuid})`}
        strokeWidth="1.5"
        stroke={colors.primary}
        points={polylinePoints}
        variants={polylineTransitionStates}
      />
    </motion.svg>
  )
}

export default FiatBalance
