import React from 'react'

export const PolygonIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 15}
    height={props.height || 13}
    viewBox="0 0 15 13"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.87 3.683a.911.911 0 0 0-.858 0L8.01 4.863l-1.36.751-1.966 1.18a.911.911 0 0 1-.858 0l-1.538-.93a.872.872 0 0 1-.43-.75V3.326c0-.286.144-.572.43-.751l1.538-.894a.911.911 0 0 1 .858 0l1.538.93c.25.143.429.429.429.75v1.18l1.359-.786V2.539a.882.882 0 0 0-.43-.751L4.72.108a.911.911 0 0 0-.859 0L.93 1.823a.787.787 0 0 0-.429.715V5.9c0 .286.143.573.43.751l2.896 1.681c.25.143.572.143.858 0L6.65 7.188 8.01 6.4l1.966-1.144a.911.911 0 0 1 .859 0l1.537.894c.25.143.43.429.43.75V8.69a.882.882 0 0 1-.43.75l-1.502.895a.911.911 0 0 1-.858 0L8.474 9.44a.872.872 0 0 1-.429-.751V7.545l-1.359.787v1.18c0 .286.143.572.43.751l2.896 1.68c.25.144.572.144.858 0l2.897-1.68c.25-.143.429-.43.429-.75V6.114a.882.882 0 0 0-.43-.751l-2.896-1.68z"
      fill={props.fill || '#4A44C6'}
      fillRule="nonzero"
    />
  </svg>
)
