import React, { PropsWithChildren } from 'react'
import styled, { keyframes } from 'styled-components'

/**************************************************************
 !!! NOTE - Deprecated, use the Framer Motion lib instead
 *************************************************************/

/**************************************************************
 ** This file is a library of animaition util loadComponents **
 *************************************************************/

/* ================== FADE ================== */

type FadeProps = { direction: 'up' | 'down' | 'left' | 'right' }

const getKeyframes = (direction: 'translateX' | 'translateY', px: string) =>
  keyframes`
from {
  opacity: 0.0001; 
  transform: ${direction}(${px});
}
to {
  opacity: 1;
  transform: translateY(0);
}
`

const Component = {
  up: styled.div`
    animation: ${getKeyframes('translateY', '16px')} 0.3s ease-in;
  `,
  down: styled.div`
    animation: ${getKeyframes('translateY', '-16px')} 0.3s ease-in;
  `,
  left: styled.div`
    animation: ${getKeyframes('translateX', '16px')} 0.3s ease-in;
  `,
  right: styled.div`
    animation: ${getKeyframes('translateX', '-16px')} 0.3s ease-in;
  `,
}

/**
 * Components performs "fade" transition on its children on mount.
 */
export const Fade: React.FC<PropsWithChildren<FadeProps>> = (
  props: FadeProps
) => {
  const _C = Component[props.direction]
  return <_C {...(props as any)} />
}
