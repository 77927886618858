import { ChainType } from 'crypto/interface'
import React from 'react'
import * as S from 'ui/Common/components/ChainDisplay/styles'
import { ChainLogo } from 'ui/Common/components/ChainLogo'
import Text from 'ui/Common/components/Text'

function getChainName(chain: ChainType) {
  if (chain == 'cardano') {
    return 'Cardano'
  } else if (chain == 'ethereum') {
    return 'Ethereum'
  } else if (chain == 'polygon') {
    return 'Polygon'
  }
}

interface ChainDisplayProps {
  chain: ChainType
  width?: number
  height?: number

  /** Toggle chain name on hover */
  toggleName?: boolean

  variant?: S.StyleVariant
}

export function ChainDisplay(props: ChainDisplayProps) {
  const width = props.width ?? 16
  const height = props.height ?? 16

  return (
    <S.ChainWrapper toggleName={props.toggleName} variant={props.variant}>
      <ChainLogo chain={props.chain} width={width} height={height} />

      <S.ToggledChainName chain={props.chain}>
        <Text variant="paragraph.5">{getChainName(props.chain)}</Text>
      </S.ToggledChainName>
    </S.ChainWrapper>
  )
}
